import { sample } from 'effector';

import { router } from '@/router';
import { PAGES } from '@utils/constants';

import * as session from '../session/internal';
import * as ui from '../ui/internal';

import * as effects from './effects';
import * as events from './events';
import { domain } from './utils';

/**
 * Trigger get info on app init
 */
sample({
	clock: ui.init,
	target: effects.getUserInfoFx,
});

/**
 * Redirect to log in if session expired
 */
sample({
	clock: session.$expired,
	source: effects.logoutFx.inFlight,
	filter: (inFlight, expired) => router.currentRoute.value.name !== PAGES.LOGIN && expired && !inFlight,
	target: domain.effect(() =>
		router.replace({
			name: PAGES.LOGIN,
			query: { redirect: 'true' },
		}),
	),
});

/**
 * Trigger reset on logout
 */
sample({
	clock: effects.logoutFx,
	target: [ui.reset, events.reset, domain.effect(() => router.push({ name: PAGES.LOGIN }))],
});

/**
 * Trigger service reset on ui reset
 */
sample({
	clock: ui.reset,
	target: events.reset,
});
