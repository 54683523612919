import { domain } from './utils';

// App init
export const init = domain.event();
// App reset
export const reset = domain.event();

// Increment loading for the given/default key
export const startLoading = domain.event<string | null | void>();
// Decrement loading for the given/default key
export const stopLoading = domain.event<string | null | void>();
// Reset loading
export const resetLoading = domain.event();

// Set modal status open for the given key
export const openModal = domain.event<string>();
// Set modal status open for the given key with data
export const openModalData = domain.event<{ name: string; data: unknown }>();
// Set modal status closed for the given key
export const closeModal = domain.event<string>();
// Close all modals
export const closeAllModals = domain.event();

// Set page/document title
export const setPageTitle = domain.event<string | void>();

// Trigger on API 403 response
export const onForbidden = domain.event();

// Module scrollTo
export const scrollTo = domain.event<ScrollToOptions>();
