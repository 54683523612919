import type { Effect } from 'effector';

import type { RequestConfig } from '@http';
import { request } from '@http';
import type { APIListResponse } from '@utils/model';

import { FEATURE_FLAGS_CONTENT_ID } from './constants';
import type { Content } from './model';
import { domain } from './utils';

/**
 * Get configuration content
 */
export const getContentFx: Effect<void, APIListResponse<Content>> = domain.effect({
	handler: () =>
		request({
			isPublic: true,
			method: 'get',
			params: {
				names: [FEATURE_FLAGS_CONTENT_ID].join(),
			},
			url: '/content/contentItems/public',
		} as RequestConfig),
});
