import type { AxiosError } from 'axios';
import axios from 'axios';
import { ElNotification } from 'element-plus';
import { isUndefined } from 'lodash-es';

import { router } from '@/router';

import * as session from '../services/session/http';
import * as ui from '../services/ui/http';

import { BEHAVIOUR, BEHAVIOUR_LIST, DEFAULT_ERROR_MESSAGE } from './error-config';
import type { ErrorBehavior } from './model';

/**
 * Error parser - analyze and parse error according configuration
 */
const parseError = ({ config, message: networkMessage, response }: AxiosError<any>) => {
	const { data: { statusMessage = '' } = {} } = response ?? {};
	const { data: { message = statusMessage || networkMessage } = {} } = response ?? {};

	let { status, config: { url = '' } = {} } = response ?? {};
	if (isUndefined(status)) {
		status = networkMessage === 'Network Error' ? 0 : 500;
		url = url || (config?.url as string);
	}

	const helper = ({ codes, paths, methods = [] }: ErrorBehavior) =>
		paths.some((path) => path.test(url || '')) &&
		codes.some((code) => code.test(`${status}`) && methods.some((method) => method.test(config?.method ?? '')));
	const { behaviour, message: errMsg } = BEHAVIOUR_LIST.find(helper) || {};

	return {
		behaviour: behaviour || BEHAVIOUR.ERROR,
		message: errMsg || message || DEFAULT_ERROR_MESSAGE,
		status,
	};
};

/**
 * Handle error and action according config
 */
export const handleError = (error: AxiosError) => {
	if (axios.isCancel(error)) {
		return null;
	}
	const { behaviour, message, status } = parseError(error);
	const actions = {
		[BEHAVIOUR.LOGOUT]: () => {
			session.endSession();
			return { message, status };
		},
		[BEHAVIOUR.IGNORE]: () => ({ message, status }),
		[BEHAVIOUR.FORBIDDEN]: () => {
			ui.onForbidden();
			return { message, status };
		},
		[BEHAVIOUR.ERROR]: () => {
			ElNotification({
				title: 'Error',
				message,
				type: 'error',
			});
			return { message, status };
		},
		[BEHAVIOUR.ERROR_PAGE]: () => {
			router.replace({ name: 'error' });
			return { message, status };
		},
	};

	return actions[behaviour]();
};
