<template>
	<router-link class="logo" :class="{ disabled: !toName }" :to="{ name: toName }" data-test="logo-link">
		<picture v-if="!isStatic" class="image-wrapper">
			<source :srcset="logoFull" :media="`(min-width: ${breakpoints.sm}px)`" />
			<img :src="logoSmall" alt="Motorway" data-test="logo-img" />
		</picture>
		<img v-if="isStatic" alt="Motorway" data-test="logo-img" :src="logoFull" />
		<span v-if="title" class="title" data-test="logo-title">{{ title }}</span>
	</router-link>
</template>

<script lang="ts" setup>
import logoFull from '@/assets/images/mw-logo-black.svg?url';
import logoSmall from '@/assets/images/mw-logo-small.svg?url';
import { breakpoints } from '@utils/breakpoints';

const {
	title = '',
	toName = '',
	isStatic = false,
} = defineProps<{
	title?: string;
	toName?: string;
	isStatic?: boolean;
}>();
</script>

<style lang="scss" scoped>
@use '@/variables.scss' as *;

.logo {
	display: flex;
	align-items: center;
	text-decoration: none;
	height: inherit;
	width: fit-content;

	&.disabled {
		pointer-events: none;
	}

	.image-wrapper {
		display: flex;
	}

	.title {
		font-size: var(--font-size-18);
		margin-left: 15px;
		color: $text-color;
		display: none;

		@include resolution('sm-and-up') {
			display: initial;
		}
	}
}
</style>
