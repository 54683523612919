<template>
	<el-container class="is-vertical error-page-container">
		<el-header class="header">
			<Logo title="Engine" to-name="error" />
		</el-header>

		<el-main class="main">
			<div class="error">
				<el-icon class="warning-icon" :size="200">
					<warning-icon />
				</el-icon>
				<div class="message">Oops, something went wrong, application has crashed. Please try again.</div>
				<el-button type="primary" @click="reset"> Restart </el-button>
			</div>
		</el-main>

		<Footer />
	</el-container>
</template>

<script lang="ts" setup>
import WarningIcon from '~icons/bxs/error';

import { Footer, Logo } from '@components';
import { userService } from '@user';

const reset = () => {
	const { host, protocol } = window.location;
	userService.logoutFx().finally(() => {
		window.location.href = `${protocol}//${host}/login`;
	});
};
</script>

<style lang="scss" scoped>
@use '@/variables.scss' as *;

.error-page-container {
	background-color: var(--el-color-white);

	.header {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		height: $header-height;
		background-color: $brand-yellow;
		box-shadow: 0 0 5px -1px var(--el-color-black);
	}

	.main {
		height: calc(100vh - #{$header-height} - #{$footer-height});

		.error {
			max-width: 400px;
			margin: 100px auto;
			text-align: center;

			.warning-icon {
				color: var(--el-color-error);
			}

			.message {
				margin-bottom: 40px;
				font-size: var(--font-size-20);
			}
		}
	}
}
</style>
