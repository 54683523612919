import '@/core/init'; // eslint-disable-line
import 'element-plus/theme-chalk/src/base.scss'; // eslint-disable-line
import 'element-plus/theme-chalk/src/notification.scss'; // eslint-disable-line
import '@/assets/styles/theme.scss';
import '@/assets/styles/theme-dark.scss';

import { createApp } from 'vue';

import App from './App.vue';
import { router } from './router';
import { initDatadogRum } from '@utils/datadog';

const app = createApp(App);
app.config.performance = true;

initDatadogRum();

app.use(router).mount('#app');
