import { sample } from 'effector';
import { ElNotification } from 'element-plus';

import { IS_PROD } from '@utils/globals';
import { delay } from 'patronum';

import * as effects from './effects';
import * as events from './events';
import { domain } from './utils';
import * as versionStore from './version.store';

/**
 * Notify about new releases
 */
sample({
	clock: versionStore.$hasNewVersion,
	filter: versionStore.$hasNewVersion,
	target: domain.effect(() => {
		ElNotification({
			type: 'warning',
			title: 'New version available',
			message: 'Please refresh the page',
			showClose: false,
			position: 'bottom-right',
			duration: 0,
			customClass: 'version-popup',
		});
	}),
});

/**
 * Start version check
 */
IS_PROD &&
	delay({
		source: sample({ clock: [events.init, effects.getVersionFx.finally] }),
		timeout: 10 * 60 * 1000,
		target: effects.getVersionFx,
	});
