import { shallowRef } from 'vue';
import type { RouteRecordRaw } from 'vue-router';
import ModuleIcon from '~icons/clarity/network-settings-solid';

import { PAGES as ROOT_PAGES } from '@utils/constants';
import type { Module } from '@utils/model';

import { PAGES } from './constants';

const routes: RouteRecordRaw[] = [
	{
		path: '/config-manager',
		name: PAGES.HOME,
		component: () => import(/* webpackChunkName: "cfm" */ './pages/Home.vue'),
		meta: {
			documentTitle: 'Config Manager',
			title: 'Config Manager',
			subtitle: 'Configurations',
			isAllowed: ({ canAccessConfigManager }) => canAccessConfigManager || ROOT_PAGES.MODULES,
		},
		children: [
			{
				path: '',
				name: PAGES.LIST,
				component: () => import(/* webpackChunkName: "cfm" */ './components/Table.vue'),
				meta: {
					documentTitle: 'Config Manager',
					title: 'Config Manager',
					subtitle: 'Configurations',
					isAllowed: ({ canAccessConfigManager }) => canAccessConfigManager || ROOT_PAGES.MODULES,
				},
			},
			{
				path: 'create',
				name: PAGES.CREATE,
				component: () => import(/* webpackChunkName: "cfm" */ './pages/Create.vue'),
				meta: {
					documentTitle: 'Config Manager / Create',
					title: 'Config Manager',
					subtitle: 'Configuration / Create',
					isAllowed: ({ canAccessConfigManager, canCreateConfigManager }) =>
						!canAccessConfigManager ? ROOT_PAGES.MODULES : canCreateConfigManager || PAGES.LIST,
				},
			},
			{
				path: ':id(\\d+)',
				name: PAGES.DETAILS,
				component: () => import(/* webpackChunkName: "cfm" */ './pages/Details.vue'),
				props: true,
				meta: {
					documentTitle: 'Config Manager / Details',
					title: 'Config Manager',
					subtitle: 'Configuration / Details',
					isAllowed: ({ canAccessConfigManager }) => canAccessConfigManager || ROOT_PAGES.MODULES,
				},
			},
		],
	},
];

export const CONFIG_MANAGER_MODULE: Module = {
	id: PAGES.HOME,
	link: { name: PAGES.LIST },
	title: 'Config Manager',
	icon: shallowRef(ModuleIcon),
	enabled: ({ canAccessConfigManager }) => canAccessConfigManager,
	routes,
};
