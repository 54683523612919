import { sample } from 'effector';

import * as ui from '../ui/internal';

import * as events from './events';

/**
 * Trigger service reset on ui reset
 */
sample({
	clock: ui.reset,
	target: events.reset,
});
