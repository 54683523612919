export enum PAGES {
	HOME = 'mlops-manager',
	MODELS_LIST = 'mlops-manager.list',
	MODEL_CREATE = 'mlops-manager.create',
	MODEL_DETAILS = 'mlops-manager.details',
	MODEL_TEST_DETAILS = 'mlops-manager.test-details',
	PIPELINES = 'mlops-manager.pipelines',
	PREDICTIONS = 'mlops-manager.predictions',
	PREDICTION_DETAILS = 'mlops-manager.prediction-details',
	IMAGES = 'mlops-manager.images',
	IMAGE_DETAILS = 'mlops-manager.image-details',
	IMAGE_DETAILS_ALT = 'mlops-manager.image-details-alt',
	PIPELINES_V3 = 'mlops-manager.pipelines-v3',
	PIPELINE_DETAILS_V3 = 'mlops-manager.pipeline-details-v3',
	PIPELINE_VERSIONS_V3 = 'mlops-manager.pipeline-versions-v3',
	SETTINGS = 'mlops-manager.settings',
}

export enum DETAIL_TABS {
	EDITOR = 'editor',
	DELETE = 'delete',
	VERSIONS = 'versions',
	VERSIONS_HISTORY = 'versions_history',
	CONFIGS = 'configs',
	EXPORTS = 'exports',
	TESTS = 'tests',
}

export const NEW_MODEL_TEMPLATE = JSON.stringify(
	{
		slug: '',
		name: '',
		dataset: [],
		source: 'onnx',
		type: 'classification | object_detection | segmentation',
		labels: [
			{
				label: 'Label Name',
				value: 'CLASS_LABEL',
			},
		],
		labelAliases: {
			'old-label': 'new-label',
		},
		configKey: ' ',
		exportFormat: 'darwin_json_2',
		exportFilters: {},
	},
	null,
	2,
);

export const NEW_VERSION_TEMPLATE = JSON.stringify(
	{
		exportName: '',
		key: '',
		imageWidth: 0,
		imageHeight: 0,
		imageChannels: 0,
	},
	null,
	2,
);

export const GENERATE_EXPORT_TEMPLATE = JSON.stringify(
	{
		exportName: 'v7-export-name-here',
		fileLimit: null,
	},
	null,
	2,
);

export const CUSTOM_TRAINING_TEMPLATE = JSON.stringify(
	{
		experimentTag: 'pr-number-here',
		overrides: {
			vcpu: 16,
			memory: 32768,
			instanceType: 'aws-instance-type',
		},
	},
	null,
	2,
);
