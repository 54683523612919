<template>
	<el-config-provider size="large">
		<router-view />
	</el-config-provider>
</template>
<style lang="scss">
@font-face {
	font-family: 'NewTransport';
	src: url('@/assets/fonts/NewTransport-Regular.woff2') format('woff2');
	font-weight: normal;
}

@font-face {
	font-family: 'NewTransport';
	src: url('@/assets/fonts/NewTransport-Semibold.woff2') format('woff2');
	font-weight: 600;
}

@font-face {
	font-family: 'NewTransport';
	src: url('@/assets/fonts/NewTransport-Bold.woff2') format('woff2');
	font-weight: bold;
}

@font-face {
	font-display: swap;
	font-family: 'UK Number Plate';
	font-weight: normal;
	src:
		url('@/assets/fonts/UKNumberPlate.woff2') format('woff2'),
		url('@/assets/fonts/UKNumberPlate.woff') format('woff');
}

body,
#app {
	height: 100%;
	width: 100%;
	max-height: 100vh;
}

body {
	font-family: NewTransport, Avenir, Helvetica, Arial, sans-serif;
	font-size: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	min-width: 360px;
}

#app {
	background-color: var(--app-background-color);
}
</style>
