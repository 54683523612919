<template>
	<el-radio-group v-if="!hideButtons" v-model="mode" class="switch-mode" size="default" data-test="mode-switch">
		<el-radio-button v-if="table" :label="VIEW_MODE.TABLE" data-test="mode-switch-table">
			<table-icon />
		</el-radio-button>
		<el-radio-button v-if="cards" :label="VIEW_MODE.CARDS" data-test="mode-switch-cards">
			<cards-icon />
		</el-radio-button>
	</el-radio-group>
</template>

<script lang="ts" setup>
import { computed, watchEffect } from 'vue';
import TableIcon from '~icons/codicon/table';
import CardsIcon from '~icons/ph/cards';

import { windowService } from '@tools';
import type { Breakpoints } from '@utils/breakpoints';
import { breakpoints } from '@utils/breakpoints';
import { VIEW_MODE } from '@utils/constants';

const {
	table = false,
	cards = false,
	showCardsWhen,
	hideWhen,
} = defineProps<{
	table?: boolean;
	cards?: boolean;
	showCardsWhen?: keyof Breakpoints;
	hideWhen?: keyof Breakpoints;
}>();

const mode = defineModel<VIEW_MODE>({
	default: VIEW_MODE.TABLE,
	required: true,
});

const hideButtons = computed(() => {
	return windowService.$.width <= breakpoints[hideWhen ?? 'sm'];
});

const toggleCards = computed(() => {
	return windowService.$.width <= breakpoints[showCardsWhen ?? 'md'];
});

watchEffect(() => {
	toggleCards.value && (mode.value = VIEW_MODE.CARDS);
});
</script>

<style lang="scss" scoped>
.switch-mode {
	line-height: initial;
	margin-left: 10px;

	:deep(.el-radio-button__inner) {
		padding: 6px 14px 4px;
	}
}
</style>
