import type { Store } from 'effector';

import { persistStore } from '@utils/store';

import { FEATURE_FLAGS_CONTENT_ID } from './constants';
import * as effects from './effects';
import type { Content, FeatureFlags } from './model';
import { domain } from './utils';

/**
 * Create content store
 */
export const $content: Store<Record<string, Content['content']>> = persistStore(domain.store({}), {
	key: 'agent.content',
	sync: true,
}).on(effects.getContentFx.doneData, (_, { data }) =>
	data.reduce((acc, { content, name }) => ({ ...acc, [name]: content }), {}),
);

/**
 * Feature flags
 */
export const $features: Store<FeatureFlags> = $content.map(({ [FEATURE_FLAGS_CONTENT_ID]: content }) => content || {});
