import * as events from './events';
import type { ModalsState } from './model';
import { domain } from './utils';

/**
 * Create modals store
 */
export const $modals = domain
	.store<ModalsState>({})
	.on(events.openModal, (state, name) => ({ ...state, [name]: true }))
	.on(events.openModalData, (state, { name, data }) => ({ ...state, [name]: data }))
	.on(events.closeModal, (state, name) => ({ ...state, [name]: false }))
	.reset([events.closeAllModals, events.reset]);
