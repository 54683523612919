import type { AxiosInstance } from 'axios';
import axios from 'axios';

import { APP_REQUEST_HEADER, BACKEND_URL, PROVIDERS_VERSIONS_HEADER, VERSION_REQUEST_HEADER } from '@utils/globals';

/**
 * Create initial axios instance
 */
export const axiosInstance: AxiosInstance = axios.create({
	baseURL: BACKEND_URL,
	headers: {
		'Content-Type': 'application/json',
		'x-mway-version': VERSION_REQUEST_HEADER,
		'x-mway-app': APP_REQUEST_HEADER,
		...(PROVIDERS_VERSIONS_HEADER && { 'x-mway-providers-versions': PROVIDERS_VERSIONS_HEADER }),
	},
	withCredentials: true,
});
