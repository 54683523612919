import type { StoreWritable } from 'effector';

import { domain as globalDomain } from './domain';

interface DefaultConfig {
	key: string;
	storage?: Storage;
	sync?: boolean;
}

const defaultConfig = {
	key: 'persist',
	storage: localStorage,
	sync: false,
};

const getItem = (key: string, storage: Storage) => {
	const item = storage.getItem(key);
	return item ? JSON.parse(item) : null;
};

/**
 * Keeps in sync store data with chosen storage
 */
export const persistStore = <T>(store: StoreWritable<T>, config: DefaultConfig): StoreWritable<T> => {
	const { key, storage, sync } = { ...defaultConfig, ...config };

	const rehydrate = globalDomain.event('rehydrate');

	store.on(rehydrate, () => getItem(key, storage) || store.defaultState);
	rehydrate();

	sync && storage === localStorage && addEventListener('storage', () => rehydrate());

	store.watch((state) => storage.setItem(key, JSON.stringify(state)));

	return store;
};
