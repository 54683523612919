import './side-effects';

import * as events from './events';
import * as loadingStore from './loading.store';
import * as modalsStore from './modals.store';
import * as pageStore from './page.store';

export * from './model';

export const uiService = {
	...events,
	...loadingStore,
	...modalsStore,
	...pageStore,
};
