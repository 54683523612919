import type { Store } from 'effector';

import { TIME_60_MINUTES } from '@utils/constants';
import { persistStore } from '@utils/store';

import * as events from './events';
import { domain } from './utils';

/**
 * Create session store
 */
export const $expires: Store<number> = persistStore(domain.store(0), { key: 'engine.session', sync: true })
	.on(events.setSession, () => Date.now() + TIME_60_MINUTES)
	.reset([events.endSession, events.reset]);

/**
 * Calculate session validity
 */
export const $expired = $expires.map((expires) => Date.now() > expires);
