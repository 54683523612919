import type { AxiosResponse } from 'axios';
import type { Effect } from 'effector';

import { axiosInstance } from '@/core/http/axios';

import { domain } from './utils';

export const getVersionFx: Effect<void, AxiosResponse<string>> = domain.effect({
	handler: () => axiosInstance.get(`${window.location.origin}/version.txt`),
});
