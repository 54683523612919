export const {
	APP_REQUEST_HEADER,
	BACKEND_URL,
	NODE_ENV: ENVIRONMENT,
	PROVIDERS_VERSIONS_HEADER,
	RELEASE_VERSION,
	VERSION_REQUEST_HEADER,
	SENTRY_DSN,
} = window?.MW_CONFIG || {};

export const IS_PROD = ENVIRONMENT === 'production';
