import './side-effects';

import * as effects from './effects';
import * as events from './events';
import * as store from './store';

export const userService = {
	...effects,
	...events,
	...store,
};
