import { sample } from 'effector';
import { eq, gt } from 'lodash-es';

import * as ui from '../ui/internal';

import * as effects from './effects';
import * as events from './events';
import { domain } from './utils';

/**
 * Service reset on app reset
 */
sample({
	clock: ui.reset,
	target: events.reset,
});

/**
 * Trigger get agents list on app init
 */
sample({
	clock: ui.init,
	target: effects.getAgentsFx,
});

/**
 * Request all agents pages
 */
sample({
	clock: effects.getAgentsFx.doneData,
	filter: ({ meta }) => gt(meta?.pages, 1) && eq(meta?.pageNumber, 1),
	fn: ({ meta }) => meta?.pages,
	target: domain.effect((pages: number) =>
		Promise.all(
			Array.from({ length: pages - 1 }, (_, idx) => idx + 2).map((pageNumber) => effects.getAgentsFx({ pageNumber })),
		),
	),
});
