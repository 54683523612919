import { reactive, watchEffect } from 'vue';

import { VIEW_MODE } from '@utils/constants';

interface State {
	timestamp: number;
	redirectTo: string | null;
	view: VIEW_MODE;
}

const KEY = 'engine.config';

const state = reactive<State>({
	timestamp: 0,
	redirectTo: null,
	view: VIEW_MODE.TABLE,
});

export const configService = {
	get $() {
		return state;
	},
	setRedirectTo(redirectTo: State['redirectTo']) {
		Object.assign(state, {
			timestamp: Date.now(),
			redirectTo,
		});
	},
	setViewMode(view: State['view']) {
		Object.assign(state, {
			timestamp: Date.now(),
			view,
		});
	},
};

const hydrate = () => {
	try {
		const data = localStorage.getItem(KEY);
		if (data) {
			const parsed = JSON.parse(data);
			if (parsed.timestamp > state.timestamp) {
				Object.assign(state, parsed);
			}
		}
	} catch {
		/**/
	}
};

hydrate();

watchEffect(() => {
	localStorage.setItem(KEY, JSON.stringify(state));
});

addEventListener('storage', hydrate);
