import * as events from './events';
import { domain } from './utils';

const TITLE = 'Motorway Engine';

/**
 * Create page title store
 */
export const $title = domain
	.store<string>(TITLE)
	.on(events.setPageTitle, (_, title) => `${TITLE} - ${title}`)
	.reset(events.reset);

/**
 * Set page title
 */
$title.watch((title) => {
	document.title = title;
});
