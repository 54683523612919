import type { AxiosError } from 'axios';
import type { Effect } from 'effector';

import type { RequestConfig } from '@http';
import { request } from '@http';
import { TIME_60_MINUTES } from '@utils/constants';

import type { UserInfo } from './model';
import { domain } from './utils';

/**
 * User logout
 */
export const logoutFx: Effect<void, void, AxiosError> = domain.effect({
	handler: () =>
		request({
			isPublic: true,
			method: 'post',
			url: '/dealership/user/logout',
		} as RequestConfig),
});

/**
 * Get current user info
 */
export const getUserInfoFx: Effect<void, UserInfo, AxiosError> = domain.effect({
	handler: () =>
		request({
			cacheTTL: TIME_60_MINUTES,
			method: 'get',
			url: '/dealership/user/info',
		} as RequestConfig),
});
