import { shallowRef } from 'vue';
import type { RouteRecordRaw } from 'vue-router';
import ModuleIcon from '~icons/carbon/email';

import type { Module } from '@utils/model';

import { PAGES } from './constants';

const routes: RouteRecordRaw[] = [
	{
		path: '/braze-campaigns',
		name: PAGES.HOME,
		component: () => import(/* webpackChunkName: "bzc" */ './pages/index.vue'),
		meta: {
			documentTitle: 'Braze Campaigns',
			title: 'Braze Campaigns',
			subtitle: 'Campaigns',
		},
		children: [
			{
				path: '',
				name: PAGES.LIST,
				component: () => import(/* webpackChunkName: "bzc" */ './pages/Campaigns/index.vue'),
				meta: {
					documentTitle: 'Braze Campaigns',
					title: 'Braze Campaigns',
					subtitle: 'Campaigns',
				},
			},
			{
				path: 'create',
				name: PAGES.CREATE,
				component: () => import(/* webpackChunkName: "bzc" */ './pages/CampaignCreate/index.vue'),
				meta: {
					documentTitle: 'Braze Campaign / Create',
					title: 'Braze Campaigns',
					subtitle: 'Campaign / Create',
				},
			},
			{
				path: ':id(\\d+)',
				name: PAGES.DETAILS,
				component: () => import(/* webpackChunkName: "bzc" */ './pages/CampaignDetails/index.vue'),
				props: true,
				meta: {
					documentTitle: 'Braze Campaign / Details',
					title: 'Braze Campaigns',
					subtitle: 'Campaign / Details',
				},
			},
		],
	},
];

export const BRAZE_CAMPAIGNS_MODULE: Module = {
	id: PAGES.HOME,
	link: { name: PAGES.LIST },
	title: 'Braze Campaigns',
	icon: shallowRef(ModuleIcon),
	enabled: ({ canAccessCRMTrigger }) => canAccessCRMTrigger,
	routes,
};
