import type { AxiosError } from 'axios';
import type { Effect } from 'effector';

import type { RequestConfig } from '@http';
import { request } from '@http';
import { TIME_24_HOURS, TIME_60_MINUTES } from '@utils/constants';
import type { APIListResponse, APIResponse } from '@utils/model';

import type { GetAgentsParams, ListAgent, Permission, Role } from './model';
import { domain } from './utils';

/**
 * Get agents list
 */
export const getAgentsFx: Effect<GetAgentsParams, APIListResponse<ListAgent>, AxiosError> = domain.effect({
	handler: (params) => {
		const { pageNumber } = params || {};
		return request({
			headers: {
				'use-v3-jsend': true,
			},
			cacheTTL: TIME_60_MINUTES,
			params: {
				include: 'permissions',
				...(pageNumber && { pageNumber }),
			},
			method: 'get',
			url: '/agent',
		} as RequestConfig);
	},
});

/**
 * Get agent roles
 */
export const getRolesFx: Effect<void, Role[], AxiosError> = domain.effect({
	handler: () =>
		request({
			cacheTTL: TIME_24_HOURS,
			params: {
				include: 'permissions',
			},
			method: 'get',
			url: '/agent/roles',
		} as RequestConfig),
});

/**
 * Get agent permissions
 */
export const getPermissionsFx: Effect<void, APIResponse<Permission[]>, AxiosError> = domain.effect({
	handler: () =>
		request({
			cacheTTL: TIME_24_HOURS,
			method: 'get',
			url: '/agent/permissions',
		} as RequestConfig),
});
