import { datadogRum } from '@datadog/browser-rum-slim';

import { ENVIRONMENT, RELEASE_VERSION } from './globals';

export const initDatadogRum = () => {
	if (ENVIRONMENT && ENVIRONMENT !== 'development') {
		datadogRum.init({
			applicationId: '2ab106f9-ec06-45f4-9836-f05aedc2b74d',
			clientToken: 'pub335f6408ac74186deeefbe89afb2dc5f',
			defaultPrivacyLevel: 'mask-user-input',
			env: ENVIRONMENT,
			service: 'engine-dashboard',
			sessionReplaySampleRate: 0,
			site: 'datadoghq.eu',
			trackLongTasks: true,
			trackResources: true,
			trackUserInteractions: true,
			version: RELEASE_VERSION,
			beforeSend: (event) => {
				return !(event.type === 'error' && event.error.message.includes('ResizeObserver'));
			},
		});
	}
};
