import { gt, sortBy } from 'lodash-es';

import { PERMISSION_GROUPS, PERMISSIONS_LIST } from '@utils/constants';

import * as effects from './effects';
import * as events from './events';
import type { ListAgent, Permission, Role } from './model';
import { domain } from './utils';

/**
 * All agents store
 */
export const $agents = domain
	.store<ListAgent[]>([])
	.on(effects.getAgentsFx.doneData, (state, { data, meta }) => (gt(meta?.pageNumber, 1) ? [...state, ...data] : data))
	.on(events.addAgent, (agents, agent) => [...agents, agent])
	.on(events.updateAgent, (agents, agent) => agents.map((it) => (it.id === agent.id ? agent : it)))
	.on(events.deleteAgent, (agents, id) =>
		agents.map((agent) =>
			agent.id === id
				? {
						...agent,
						isDeleted: true,
				  }
				: agent,
		),
	)
	.reset(events.reset);

/**
 * Filter active agents
 */
export const $activeAgentsList = $agents.map((agents) => agents.filter(({ isDeleted }) => !isDeleted));

/**
 * Map agents to ids
 */
export const $agentsMap = domain
	.store<Record<number, ListAgent>>({})
	.on($agents, (_, agents) => agents.reduce((acc, agent) => ({ ...acc, [agent.id]: agent }), {}))
	.reset(events.reset);

/**
 * Roles store
 */
export const $roles = domain
	.store<Role[]>([])
	.on(effects.getRolesFx.doneData, (_, data) => data)
	.reset(events.reset);

/**
 * Permissions store
 */
export const $permissions = domain
	.store<Permission[]>([] as Permission[])
	.on(effects.getPermissionsFx.doneData, (_, { data }) => sortBy(data, ['displayOrder']))
	.reset(events.reset);

/**
 * Create permission groups
 */
export const $permissionGroups = $permissions.map((list) => {
	const notGrouped = list.filter(({ name }) => !PERMISSIONS_LIST.includes(name));
	return PERMISSION_GROUPS.map(({ label, data }) => ({
		label,
		data: [
			...(data.map((it) => list.find(({ name }) => name === it)) as Permission[]),
			...(label === 'Other' ? notGrouped : []),
		],
	}));
});
