<template>
	<el-container v-if="user.isLoggedIn" class="is-vertical modules-page-container">
		<el-header class="header">
			<Logo title="Engine" />
			<UserMenu />
		</el-header>

		<el-main class="main">
			<el-container class="modules">
				<el-empty v-if="!modules?.length" :image-size="200" description="Seems you dont have access ..." />
				<router-link v-for="module of modules" :key="module.title" :to="module.link">
					<el-card shadow="hover" class="module" data-test="module-card">
						<el-icon class="module-icon">
							<component :is="module.icon" :color="module.iconColor" />
						</el-icon>
						<div class="title">
							<span data-test="module-name">{{ module.title }}</span>
						</div>
					</el-card>
				</router-link>
			</el-container>
		</el-main>

		<Footer />
	</el-container>
</template>

<script lang="ts" setup>
import { useStore } from 'effector-vue/composition';

import { Footer, Logo } from '@components';
import { UserMenu } from '@containers';
import { userService } from '@user';
import { usePageInit } from '@utils/hooks';

const { user } = usePageInit();
const modules = useStore(userService.$modules);
</script>

<style lang="scss" scoped>
@use '@/variables.scss' as *;

.modules-page-container {
	background-color: var(--el-color-white);

	.header {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		height: $header-height;
		background-color: $brand-yellow;
		box-shadow: 0 0 5px -1px var(--el-color-black);
	}

	.main {
		height: calc(100vh - #{$header-height} - #{$footer-height});

		.modules {
			justify-content: center;
			flex-wrap: wrap;

			a {
				text-decoration: none;
			}

			.module {
				--el-card-padding: 10px;
				margin: 10px;
				text-align: center;
				font-size: var(--font-size-14);
				font-weight: bold;
				color: var(--el-text-color-regular);
				cursor: pointer;
				width: 120px;

				&:hover {
					color: var(--el-color-primary);
				}

				.module-icon {
					height: 50px;
					width: 50px;

					svg {
						height: inherit;
						width: inherit;
					}
				}

				.title {
					height: 3rem;
					display: flex;
					flex-direction: column;
					justify-content: center;
				}

				@include resolution('sm-and-up') {
					font-size: var(--font-size-20);
					width: 240px;

					.module-icon {
						height: 100px;
						width: 100px;
					}

					.title {
						height: 3.5rem;
					}
				}
			}
		}
	}
}
</style>
