import type { RouteRecordRaw } from 'vue-router';
import { createRouter, createWebHistory, START_LOCATION } from 'vue-router';

import { MODULES } from '@/modules';
import { Error, Login, Modules } from '@pages';
import { sessionService } from '@session';
import { configService } from '@tools';
import { uiService } from '@ui';
import { userService } from '@user';
import { PAGES, PUBLIC_PAGES } from '@utils/constants';

const routes: Array<RouteRecordRaw> = [
	{
		path: '/',
		name: PAGES.MODULES,
		component: Modules,
		meta: {
			documentTitle: 'Modules',
		},
	},
	{
		path: '/login',
		name: PAGES.LOGIN,
		component: Login,
		meta: {
			documentTitle: 'Login',
		},
	},
	{
		path: '/error',
		name: PAGES.ERROR,
		component: Error,
		meta: {
			documentTitle: 'Error',
		},
	},
	...MODULES.reduce((acc, { routes }) => [...acc, ...routes], [] as Array<RouteRecordRaw>),
	{
		path: '/:pathMatch(.*)*',
		redirect: { name: PAGES.MODULES },
	},
];

export const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	routes,
});

router.beforeEach((to, from, next) => {
	const { _authorized, redirect } = to.query;
	// set redirect path
	if ((from.name === PAGES.LOGIN && from.redirectedFrom?.fullPath) || redirect === 'true') {
		const redirectTo =
			from.redirectedFrom?.name === PAGES.MODULES ? null : from.redirectedFrom?.fullPath ?? (from.fullPath || null);
		configService.setRedirectTo(redirectTo);
	}
	// check if its redirection from authorization and redirect
	if (_authorized === '1') {
		sessionService.setSession();
		next(configService.$.redirectTo || { name: PAGES.MODULES });
		configService.setRedirectTo(null);
		// redirect to modules if logged in
	} else if (to.name === PAGES.LOGIN && sessionService.valid) {
		next({ name: PAGES.MODULES, replace: true });
		// check if first load is error page and redirect
	} else if (to.name === PAGES.ERROR && from === START_LOCATION) {
		next({ name: PAGES.MODULES, replace: true });
		// check if page is not public and use is logged in
	} else if (!PUBLIC_PAGES.includes(to.name as PAGES) && !sessionService.valid) {
		next({ name: PAGES.LOGIN });
		// check if page has permission and redirect
	} else if (to.meta.isAllowed) {
		const path = to.meta.isAllowed(userService.$userPermissions.getState());
		path === true ? next() : next({ name: path || PAGES.MODULES });
		// transition to the next page
	} else next();
});

router.afterEach((to) => {
	uiService.setPageTitle(to.meta.documentTitle);
});
