import { combine } from 'effector';

import { MODULES } from '@/modules';
import { PERMISSIONS, ROLES } from '@utils/constants';
import { persistStore } from '@utils/store';

import * as session from '../session/internal';

import * as effects from './effects';
import * as events from './events';
import type { UserInfo } from './model';
import { domain } from './utils';

/**
 * Current user info main store
 */
export const $info = domain
	.store<UserInfo>({} as UserInfo)
	.on(effects.getUserInfoFx.doneData, (_, info) => info)
	.reset(events.reset);

/**
 * Current user permissions
 */
export const $userPermissions = persistStore(
	domain.store(
		{
			canAccessCRMTrigger: false,
			canAccessConfigManager: false,
			canCreateConfigManager: false,
			canReadConfigManager: false,
			canWriteConfigManager: false,
			canDeleteConfigManager: false,
			canAccessAgentManager: false,
			canAccessSellerManager: false,
			canAccessSellerManagerGDPR: false,
			canViewUsers: false,
			canManageUsers: false,
			canEditLeadAssignment: false,
			canEditUserPermissions: false,
			canEditProfilePicture: false,
			canAccessMLOpsManager: false,
		},
		{ name: '$userPermissions' },
	),
	{ key: 'engine.permissions', sync: true },
)
	.on($info, (_, info) => {
		const { computedPermissions = [] } = info;
		return {
			// CRM
			canAccessCRMTrigger: computedPermissions.includes(PERMISSIONS.ACCESS_CRM_TRIGGER),
			// Config Manager
			canAccessConfigManager: computedPermissions.includes(PERMISSIONS.CONFIG_MANAGER_ACCESS),
			canCreateConfigManager: computedPermissions.includes(PERMISSIONS.CONFIG_MANAGER_CREATE),
			canReadConfigManager: computedPermissions.includes(PERMISSIONS.CONFIG_MANAGER_READ),
			canWriteConfigManager: computedPermissions.includes(PERMISSIONS.CONFIG_MANAGER_WRITE),
			canDeleteConfigManager: computedPermissions.includes(PERMISSIONS.CONFIG_MANAGER_DELETE),
			// MLOps Manager
			canAccessMLOpsManager: computedPermissions.includes(PERMISSIONS.MLOPS_MANAGER_ACCESS),
			// Engine Other
			canAccessAgentManager: computedPermissions.includes(PERMISSIONS.AGENT_MANAGER_ACCESS),
			canAccessSellerManager: computedPermissions.includes(PERMISSIONS.SELLER_MANAGER_ACCESS),
			canAccessSellerManagerGDPR: computedPermissions.includes(PERMISSIONS.SELLER_MANAGER_GDPR_ACCESS),
			// User Management
			canViewUsers: computedPermissions.includes(PERMISSIONS.USERS_VIEW),
			canManageUsers: computedPermissions.includes(PERMISSIONS.USERS_MANAGEMENT),
			canEditLeadAssignment: computedPermissions.includes(PERMISSIONS.USERS_EDIT_LEAD_ASSIGNMENT),
			canEditUserPermissions: computedPermissions.includes(PERMISSIONS.USERS_EDIT_PERMISSIONS),
			canEditProfilePicture: computedPermissions.includes(PERMISSIONS.USERS_EDIT_PROFILE_PICTURE),
		};
	})
	.reset(events.reset);

/**
 * Current user role
 */
export const $userRole = $info.map(({ role }) => ({
	isAdmin: ROLES.ADMIN === role?.name,
	isQA: ROLES.QA === role?.name,
	isAccountManager: ROLES.ACCOUNT_MANAGER === role?.name,
	isDeveloper: ROLES.DEVELOPER === role?.name,
	isDealerSpecialist: ROLES.DEALER_SPECIALIST === role?.name,
	isDealerSupport: ROLES.DEALER_SUPPORT === role?.name,
	isDocumentReviewer: ROLES.DOCUMENT_REVIEWER === role?.name,
	isOperation: ROLES.OPERATION === role?.name,
	isSalesSpecialist: ROLES.SALES_SPECIALIST === role?.name,
	isSeniorAccountManager: ROLES.SENIOR_ACCOUNT_MANAGER === role?.name,
	isSupportSpecialist: ROLES.SUPPORT_SPECIALIST === role?.name,
}));

/**
 * Current user info
 */
export const $userInfo = combine($info, session.$expired, (info, sessionExpired) => {
	const { computedPermissions = [], role, id } = info;
	return {
		id,
		info,
		isLoggedIn: !!id,
		permissions: computedPermissions,
		role: role?.name,
		roleId: role?.id,
		sessionExpired,
	};
});

/**
 * Current user modules
 */
export const $modules = $userPermissions.map((permissions) => MODULES.filter(({ enabled }) => enabled(permissions)));
