import { shallowRef } from 'vue';
import type { RouteRecordRaw } from 'vue-router';
import ModuleIcon from '~icons/fa6-solid/users';

import { VIEW_MODE } from '@utils/constants';
import type { Module } from '@utils/model';

import { PAGES } from './constants';

const routes: RouteRecordRaw[] = [
	{
		path: '/agent-manager',
		name: PAGES.HOME,
		component: () => import(/* webpackChunkName: "amg" */ './pages/Home.vue'),
		meta: {
			documentTitle: 'Agent Manager',
			title: 'Agent Manager',
			subtitle: 'Agents',
		},
		children: [
			{
				path: '',
				name: PAGES.LIST,
				components: {
					[VIEW_MODE.TABLE]: () => import(/* webpackChunkName: "amg" */ './components/Table.vue'),
					[VIEW_MODE.CARDS]: () => import(/* webpackChunkName: "amg" */ './components/Cards.vue'),
				},
				meta: {
					documentTitle: 'Agent Manager',
					title: 'Agent Manager',
					subtitle: 'Agents',
				},
			},
			{
				path: 'create',
				name: PAGES.CREATE,
				component: () => import(/* webpackChunkName: "amg" */ './pages/Create.vue'),
				meta: {
					documentTitle: 'Agent Manager / Create',
					title: 'Agent Manager',
					subtitle: 'Agents / Create',
				},
			},
			{
				path: ':id(\\d+)',
				name: PAGES.DETAILS,
				component: () => import(/* webpackChunkName: "amg" */ './pages/Details.vue'),
				props: true,
				meta: {
					documentTitle: 'Agent Manager / Details',
					title: 'Agent Manager',
					subtitle: 'Agents / Details',
				},
			},
		],
	},
];

export const AGENT_MANAGER_MODULE: Module = {
	id: PAGES.HOME,
	link: { name: PAGES.LIST },
	title: 'Agent Manager',
	icon: shallowRef(ModuleIcon),
	enabled: ({ canAccessAgentManager }) => canAccessAgentManager,
	routes,
};
