<template>
	<el-container class="login-page-container">
		<div class="chevron" />

		<el-header class="header">
			<Logo is-static title="Engine" />
		</el-header>

		<el-main class="main">
			<el-container>
				<el-card class="card" shadow="never">
					<h1 class="title" data-test="page-title">Engine Dashboard</h1>
					<el-link data-test="login-link" :underline="false" :href="oneLoginSignInUrl">
						<img
							data-test="login-button"
							class="login-button"
							src="@/assets/images/onelogin-log-in.png"
							alt="Sign in with OneLogin"
						/>
					</el-link>
				</el-card>
			</el-container>
		</el-main>
	</el-container>
</template>

<script lang="ts" setup>
import { unref } from 'vue';
import { useBrowserLocation } from '@vueuse/core';

import { Logo } from '@components';
import { BACKEND_URL } from '@utils/globals';

const { protocol, host, origin } = unref(useBrowserLocation());
const encodedOrigin = encodeURIComponent(origin || `${protocol}//${host}`);
const oneLoginSignInUrl = `${BACKEND_URL}/dealership/user/auth/oneLogin?origin=${encodedOrigin}`;
</script>

<style lang="scss" scoped>
@use '@/variables.scss' as *;

.login-page-container {
	overflow: hidden;
	position: relative;
	height: 100vh;
	background-color: $color-white;

	.chevron {
		background-color: $brand-yellow;
		height: 100%;
		pointer-events: none;
		position: absolute;
		right: 0;
		width: 30%;
		z-index: 0;

		&::before {
			width: 400px;
			bottom: 50%;
			height: 1500px;
			content: '';
			display: block;
			position: absolute;
			background-color: $brand-yellow;
			transform: rotate(-30deg);
			transform-origin: bottom left;
		}

		&::after {
			top: 50%;
			width: 400px;
			height: 1500px;
			content: '';
			display: block;
			position: absolute;
			background-color: $brand-yellow;
			transform: rotate(30deg);
			transform-origin: top left;
		}
	}

	.header {
		height: $header-height;
		z-index: 1;
		margin: auto;

		@include resolution('sm-and-up') {
			margin: initial;
		}
	}

	.main {
		display: flex;
		align-items: center;
		flex-grow: 1;
		z-index: 1;

		.card {
			border: none;
			background-color: transparent;
			margin: auto;
			text-align: center;
			color: $text-color;

			@include resolution('sm-and-up') {
				margin: 0 0 200px 16%;
				text-align: left;
			}

			.title {
				font-size: 2rem;
				margin: 0 0 36px;

				@include resolution('sm-and-up') {
					font-size: 3rem;
				}
			}

			.text {
				margin: 24px auto;
				font-weight: bold;
				text-align: center;
				width: 280px;

				@include resolution('sm-and-up') {
					margin: 24px 0;
				}
			}

			.login-button {
				width: 280px;
			}
		}
	}
}
</style>
