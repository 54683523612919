import { AGENT_MANAGER_MODULE } from '@amg/config';
import { SELLER_MANAGER_MODULE } from '@slm/config';
import type { Module } from '@utils/model';
import { BRAZE_CAMPAIGNS_MODULE } from '@bzc/config';
import { CONFIG_MANAGER_MODULE } from '@cfm/config';
import { MLOPS_MANAGER_MODULE } from '@mlm/config';

export const MODULES: Module[] = [
	AGENT_MANAGER_MODULE,
	SELLER_MANAGER_MODULE,
	BRAZE_CAMPAIGNS_MODULE,
	CONFIG_MANAGER_MODULE,
	MLOPS_MANAGER_MODULE,
];
