import { createDomain } from 'effector';

// import { attachReduxDevTools } from '@effector/redux-devtools-adapter';

export const domain = createDomain('@root');

// import.meta.env.MODE !== 'production' &&
// 	attachReduxDevTools({
// 		name: 'Engine Dashboard',
// 		trace: true,
// 		stateTab: false,
// 	});
