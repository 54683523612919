import './side-effects';

import * as events from './events';
import * as store from './store';

export const sessionService = {
	...events,
	...store,
	get valid() {
		return Date.now() < store.$expires.getState();
	},
};
