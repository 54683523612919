import type { JsonEditorOptions } from '@containers';

export enum PAGES {
	HOME = 'config-manager',
	LIST = 'config-manager.list',
	CREATE = 'config-manager.create',
	DETAILS = 'config-manager.details',
}

export const ITEMS_PER_PAGE = 50;

export enum DETAIL_TABS {
	INTERFACE = 'interface',
	EDITOR = 'editor',
	PERMISSIONS = 'permissions',
	SETTINGS = 'settings',
	SETUP = 'setup',
	DELETE = 'delete',
	HISTORY = 'history',
}

export const configSetupOptions: JsonEditorOptions = {
	schemaUri: 'config-manager://editor/setup',
	schema: {
		uri: 'https://json-schema.org/draft/2020-12/schema',
		schema: {
			type: 'object',
			properties: {
				ui: {
					title: 'Container for UI config data',
					$ref: '#/definitions/ui',
				},
				validation: {
					title: 'Content validation schema',
					$ref: '#/definitions/validation',
				},
			},
			definitions: {
				schema: {
					type: 'object',
					properties: {
						uri: {
							title: 'Schema JSON URI',
							type: 'string',
						},
						schema: {
							title: 'JSON validation rules schema',
							type: 'object',
						},
						fileMatch: {
							title: 'Editor URI/ID, item should match to schemaUri',
							type: 'array',
							maxItems: 1,
							items: [{ type: 'string', pattern: '^config-manager:\\/\\/editor\\/.+$' }],
						},
					},
					additionalProperties: false,
				},
				schemaOptions: {
					type: 'object',
					properties: {
						schemaValidation: {
							title: 'The severity of problems from schema validation, default is warning',
							enum: ['error', 'warning', 'ignore'],
						},
					},
					additionalProperties: false,
				},
				validation: {
					type: 'object',
					properties: {
						schema: {
							title: 'JSON schema validation',
							$ref: '#/definitions/schema',
						},
						schemaUri: {
							title: 'Editor schema URI/ID',
							type: 'string',
							pattern: '^config-manager:\\/\\/editor\\/.+$',
						},
						schemaOptions: {
							title: 'Editor schema options',
							$ref: '#/definitions/schemaOptions',
						},
					},
					required: ['schema', 'schemaUri'],
					additionalProperties: false,
				},
				ui: {
					type: 'object',
					properties: {
						features: {
							title: 'Array of features',
							$ref: '#/definitions/features',
						},
					},
					additionalProperties: false,
				},
				features: {
					type: 'array',
					minItems: 1,
					items: {
						type: 'object',
						properties: {
							name: {
								title: 'Should match to the feature config key',
								type: 'string',
							},
						},
						required: ['name'],
					},
				},
			},
		},
		fileMatch: ['config-manager://editor/setup'],
	},
	schemaOptions: {
		schemaValidation: 'error',
	},
};
