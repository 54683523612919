<template>
	<div class="user-menu">
		<el-dropdown trigger="click">
			<div class="trigger">
				<div data-test="user-name">{{ info?.name }}</div>
				<img
					:class="['user-logo', { mini, default: !info?.profilePicture }]"
					:src="info?.profilePicture || userDefaultLogo"
					:alt="info?.name"
				/>
			</div>

			<template #dropdown>
				<el-dropdown-menu>
					<el-dropdown-item @click="userService.logoutFx" :icon="LogOutIcon">Log Out</el-dropdown-item>
				</el-dropdown-menu>
			</template>
		</el-dropdown>
	</div>
</template>

<script lang="ts" setup>
import { useStore } from 'effector-vue/composition';
import LogOutIcon from '~icons/mdi/logout-variant';

import { userService } from '@user';

import userDefaultLogo from '../assets/images/mw-logo-circle.png';

const info = useStore(userService.$info);

const { mini = false } = defineProps<{
	mini?: boolean;
}>();
</script>

<style lang="scss" scoped>
@use '@/variables.scss' as *;
@use '@/assets/styles/animations.scss' as *;

.link {
	text-decoration: none;
}

.user-menu {
	cursor: pointer;
	margin-left: auto;
	display: flex;

	.trigger {
		display: flex;
		align-items: center;
		color: $text-color;
		font-size: var(--font-size-16);

		.user-logo {
			width: 35px;
			height: 35px;
			border-radius: 50%;
			margin-left: 10px;
			animation: bounce-in-fwd 0.5s both;

			&.default {
				border: solid 2px #303133ff;
			}

			&.mini {
				width: 24px;
				height: 24px;
			}
		}
	}
}
</style>
