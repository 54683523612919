import { shallowRef } from 'vue';
import type { RouteRecordRaw } from 'vue-router';
import ModuleIcon from '~icons/eos-icons/ai-operator';

import type { Module } from '@utils/model';

import { PAGES } from './constants';

const routes: RouteRecordRaw[] = [
	{
		path: '/mlops-manager',
		name: PAGES.HOME,
		component: () => import('./pages/index.vue'),
		meta: {
			documentTitle: 'MLOps Manager',
			title: 'MLOps Manager',
			subtitle: 'Models',
		},
		children: [
			{
				path: '',
				name: PAGES.MODELS_LIST,
				component: () => import('./pages/Models/index.vue'),
				meta: {
					documentTitle: 'MLOps Manager / Models',
					title: 'MLOps Manager',
					subtitle: 'Models',
				},
			},
			{
				path: 'create',
				name: PAGES.MODEL_CREATE,
				component: () => import('./pages/ModelCreate/index.vue'),
				meta: {
					documentTitle: 'MLOps Manager / Model Create',
					title: 'MLOps Manager',
					subtitle: 'Model / Create',
				},
			},
			{
				path: 'pipelines',
				name: PAGES.PIPELINES,
				component: () => import('./pages/Pipelines/index.vue'),
				meta: {
					documentTitle: 'MLOps Manager / Pipelines',
					title: 'MLOps Manager',
					subtitle: 'Pipelines',
				},
			},
			{
				path: 'predictions',
				name: PAGES.PREDICTIONS,
				component: () => import('./pages/Predictions/index.vue'),
				meta: {
					documentTitle: 'MLOps Manager / Predictions',
					title: 'MLOps Manager',
					subtitle: 'Predictions',
				},
			},
			{
				path: ':slug',
				name: PAGES.MODEL_DETAILS,
				component: () => import('./pages/ModelDetails/index.vue'),
				props: true,
				meta: {
					documentTitle: 'MLOps Manager / Model Details',
					title: 'MLOps Manager',
					subtitle: 'Model / Details',
				},
			},
			{
				path: ':slug/:version(\\d+)/tests/:testId(\\d+)',
				name: PAGES.MODEL_TEST_DETAILS,
				component: () => import('./pages/TestDetails/index.vue'),
				props: true,
				meta: {
					documentTitle: 'MLOps Manager / Test Details',
					title: 'MLOps Manager',
					subtitle: 'Test / Details',
				},
			},
			{
				path: 'predictions/:id(\\d+)',
				name: PAGES.PREDICTION_DETAILS,
				component: () => import('./pages/PredictionDetails/index.vue'),
				props: true,
				meta: {
					documentTitle: 'MLOps Manager / Prediction Details',
					title: 'MLOps Manager',
					subtitle: 'Prediction / Details',
				},
			},
			{
				path: 'images',
				name: PAGES.IMAGES,
				component: () => import('./pages/Images/index.vue'),
				props: true,
				meta: {
					documentTitle: 'MLOps Manager / Images',
					title: 'MLOps Manager',
					subtitle: 'Images',
				},
			},
			{
				path: 'images/vid/:vehicleImageId(\\d+)',
				name: PAGES.IMAGE_DETAILS_ALT,
				component: () => import('./pages/ImageDetails/index.vue'),
				props: true,
				meta: {
					documentTitle: 'MLOps Manager / Images / Details',
					title: 'MLOps Manager',
					subtitle: 'Images / Details',
				},
			},
			{
				path: 'images/:id(\\d+)',
				name: PAGES.IMAGE_DETAILS,
				component: () => import('./pages/ImageDetails/index.vue'),
				props: true,
				meta: {
					documentTitle: 'MLOps Manager / Images / Details',
					title: 'MLOps Manager',
					subtitle: 'Images / Details',
				},
			},
			{
				path: 'pipelines-v3',
				name: PAGES.PIPELINES_V3,
				component: () => import('./pages/PipelinesV3/index.vue'),
				props: true,
				meta: {
					documentTitle: 'MLOps Manager / Pipelines V3',
					title: 'MLOps Manager',
					subtitle: 'Pipelines V3',
				},
			},
			{
				path: 'pipelines-v3/:name([a-z-.]+)',
				name: PAGES.PIPELINE_DETAILS_V3,
				component: () => import('./pages/PipelineDetailsV3/index.vue'),
				props: true,
				meta: {
					documentTitle: 'MLOps Manager / Pipelines V3 / Details',
					title: 'MLOps Manager',
					subtitle: 'Pipelines V3 / Details',
				},
			},
			{
				path: 'pipelines-v3/callllm-versions',
				name: PAGES.PIPELINE_VERSIONS_V3,
				component: () => import('./pages/PipelinesVersionsV3/index.vue'),
				props: true,
				meta: {
					documentTitle: 'MLOps Manager / Pipelines V3 / CallLlm Versions',
					title: 'MLOps Manager',
					subtitle: 'Pipelines V3 / CallLlm Versions',
				},
			},
			{
				path: 'settings',
				name: PAGES.SETTINGS,
				component: () => import('./pages/Settings/index.vue'),
				props: true,
				meta: {
					documentTitle: 'MLOps Manager / Settings',
					title: 'MLOps Manager',
					subtitle: 'Settings',
				},
			},
		],
	},
];

export const MLOPS_MANAGER_MODULE: Module = {
	id: PAGES.HOME,
	link: { name: PAGES.MODELS_LIST },
	title: 'MLOps Manager',
	icon: shallowRef(ModuleIcon),
	enabled: ({ canAccessMLOpsManager }) => canAccessMLOpsManager,
	routes,
};
