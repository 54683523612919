import type { AxiosError, AxiosRequestConfig } from 'axios';
import type { Effect } from 'effector';
import { flow } from 'lodash-es';
import qs from 'query-string';

import { QUERY_STRING_CONFIG } from '@/core/utils/constants';
import { domain } from '@/core/utils/domain';
import { sessionService } from '@session';

import * as ui from '../services/ui/http';

import { apiCache } from './api-cache';
import { axiosInstance } from './axios';
import { applyCancelToken } from './cancel-token';
import { handleError } from './error-handler';
import type { RequestConfig } from './model';

/**
 * Axios http request handler effect
 */
export const request: Effect<AxiosRequestConfig, any, AxiosError> = domain.effect({
	handler: async ({
		loadingKey,
		cacheTTL = 0,
		cacheKey = '',
		method = 'get',
		params,
		isPublic = false,
		url = '',
		updateCache = false,
		...rest
	}: RequestConfig) => {
		// end session if expired
		if (!isPublic && !sessionService.valid) {
			sessionService.endSession();
			return Promise.reject({
				response: {
					status: 401,
					message: 'Session expired',
				},
			});
		}

		// return cached response if valid/exist
		const cacheUrl = cacheKey || qs.stringifyUrl({ query: params, url }, QUERY_STRING_CONFIG);
		const cached = !updateCache && apiCache.get(cacheUrl);
		if (method.toLowerCase() === 'get' && cached) {
			return cached;
		}

		const axiosConfig = flow([applyCancelToken])({
			method,
			params,
			url,
			...rest,
		});

		try {
			ui.startLoading(loadingKey);
			const { data, headers } = await axiosInstance.request(axiosConfig);
			const response = 'x-total-count' in headers ? { list: data, total: Number(headers['x-total-count']) } : data;
			data && cacheTTL && apiCache.set(cacheUrl, response, cacheTTL);
			!isPublic && sessionService.setSession();
			return Promise.resolve(response);
		} catch (error) {
			return Promise.reject(error);
		} finally {
			setTimeout(() => ui.stopLoading(loadingKey), 100);
		}
	},
	name: 'axiosRequestFx',
});

request.fail.watch(({ error }) => handleError(error));
