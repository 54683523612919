<template>
	<el-footer class="footer" data-test="footer">
		&copy; Motorway Online Ltd - {{ new Date().getFullYear() }}
		<el-icon class="theme-switcher" :size="16" @click="toggleDark()">
			<SunIcon v-if="isDark" />
			<MoonIcon v-else />
		</el-icon>
	</el-footer>
</template>

<script lang="ts" setup>
import { useDark, useToggle } from '@vueuse/core';
import MoonIcon from '~icons/clarity/moon-line';
import SunIcon from '~icons/clarity/sun-line';

const isDark = useDark();
const toggleDark = useToggle(isDark);
</script>

<style lang="scss" scoped>
@use '@/variables.scss' as *;

.footer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: $footer-background-color;
	color: $color-white;
	font-size: var(--font-size-14);
	height: $footer-height;

	.theme-switcher {
		cursor: pointer;
	}
}
</style>
