import type { Ref } from 'vue';
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import type { Effect, Event, Store, Tuple } from 'effector';
import { clearNode, combine } from 'effector';
import { useUnit } from 'effector-vue/composition';
import { useCssVar, useDark } from '@vueuse/core';

import { uiService } from '@ui';
import { userService } from '@user';

import { PAGES } from './constants';
import type { Module } from './model';

/**
 * Create combine store with destroy on unmount
 */
export const useEffCombine = <T extends Tuple>(stores: T) => {
	const combined = combine<T>(stores);
	onUnmounted(() => {
		clearNode(combined, { deep: true });
	});
	return combined;
};

/**
 * Subscribe to multiple stores
 */
type UseEffStores<T> = { [K in keyof T]: T[K] extends Store<infer U> ? Ref<U> : Ref<T[K]> };
export const useEffStores = <T extends Tuple>(stores: T) => {
	return stores.map((store) => useUnit(store as Store<any>)) as UseEffStores<T>;
};

/**
 * Subscribe / unsubscribe to effector unit watcher
 */
export const useEffUnitWatch = <T>(
	unit: Effect<T, any, any> | Event<T> | Store<T>,
	fn: (data: T, prev: T | null) => void,
) => {
	const prev: Ref<T | null> = ref(null);
	const unwatch = unit.watch((value) => {
		fn(value, prev.value);
		prev.value = value;
	});
	onUnmounted(() => {
		unwatch();
	});
};

interface ModuleInitArgs {
	module: Module;
}

// Initialise data on page init
export const usePageInit = () => {
	const isDark = useDark();
	const [user, busy] = useEffStores([userService.$userInfo, userService.getUserInfoFx.pending]);
	const backgroundColor = useCssVar('--app-background-color');

	const color = computed(() => (isDark.value ? '#000000' : '#ffffff'));

	useEffUnitWatch(userService.$userInfo, ({ isLoggedIn }) => {
		backgroundColor.value = isLoggedIn ? color.value : 'transparent';
	});

	uiService.init();

	return { busy, user };
};

// Initialise data on module init
export const useModuleInit = ({ module }: ModuleInitArgs) => {
	const { user, busy } = usePageInit();
	const router = useRouter();

	const stores = useEffCombine([userService.$userInfo, userService.$userPermissions]);

	useEffUnitWatch(stores, ([info, permissions]) => {
		if (info.isLoggedIn) {
			const enabled = module.enabled(permissions);
			!enabled && router.replace({ name: PAGES.MODULES });
		}
	});

	return { busy, user };
};

// react on key down event
export const useKeyDown = (cb: (e: KeyboardEvent) => void) => {
	const listener = (event: KeyboardEvent) => {
		cb?.(event);
	};

	onMounted(() => {
		document.addEventListener('keydown', listener);
	});
	onUnmounted(() => {
		document.removeEventListener('keydown', listener);
	});
};
