import { reactive } from 'vue';
import { throttle } from 'lodash-es';

import { breakpoints } from '@utils/breakpoints';

const state = reactive({
	width: 0,
	height: 0,
	lteXs: false,
	lteSm: false,
	lteMd: false,
	lteLg: false,
	lteXl: false,
	lteX2l: false,
	lteX3l: false,
	gtXs: false,
	gtSm: false,
	gtMd: false,
	gtLg: false,
	gtXl: false,
	gtX2l: false,
	gtX3l: false,
});

const setSize = throttle(() => {
	state.width = window.innerWidth;
	state.height = window.innerHeight;
	state.lteXs = window.innerWidth <= breakpoints.xs;
	state.lteSm = window.innerWidth <= breakpoints.sm;
	state.lteMd = window.innerWidth <= breakpoints.md;
	state.lteLg = window.innerWidth <= breakpoints.lg;
	state.lteXl = window.innerWidth <= breakpoints.xl;
	state.lteX2l = window.innerWidth <= breakpoints.x2l;
	state.lteX3l = window.innerWidth <= breakpoints.x3l;
	state.gtXs = window.innerWidth > breakpoints.xs;
	state.gtSm = window.innerWidth > breakpoints.sm;
	state.gtMd = window.innerWidth > breakpoints.md;
	state.gtLg = window.innerWidth > breakpoints.lg;
	state.gtXl = window.innerWidth > breakpoints.xl;
	state.gtX2l = window.innerWidth > breakpoints.x2l;
	state.gtX3l = window.innerWidth > breakpoints.x3l;
}, 20);

window.addEventListener('resize', setSize);

setSize();

export const windowService = {
	get $() {
		return state;
	},
};
