import { GLOBAL_LOADING } from '@utils/constants';

import * as events from './events';
import type { LoadingState } from './model';
import { domain } from './utils';

/**
 * Create loading state store
 */
export const $loadingMap = domain
	.store<LoadingState>({ [GLOBAL_LOADING]: 0 })
	.on(events.startLoading, (state, key = GLOBAL_LOADING) =>
		key
			? {
					...state,
					[key]: state[key] !== undefined ? state[key] + 1 : 1,
			  }
			: undefined,
	)
	.on(events.stopLoading, (state, key = GLOBAL_LOADING) =>
		key
			? {
					...state,
					[key]: state[key] - 1,
			  }
			: undefined,
	)
	.reset([events.resetLoading, events.reset]);

export const $loading = $loadingMap.map((loadings) => Object.values(loadings).some((it) => !!it));
