import type { ErrorBehavior } from './model';

export const BEHAVIOUR = {
	ERROR: 'ERROR',
	ERROR_PAGE: 'ERROR_PAGE',
	IGNORE: 'IGNORE',
	LOGOUT: 'LOGOUT',
	FORBIDDEN: 'FORBIDDEN',
};

export const DEFAULT_ERROR_MESSAGE = `
  We are experiencing some problems right now.
  Please try again later.
`;

export const NOT_FOUND_ERROR_MESSAGE = 'Not found';

/**
 * Errors behaviour list
 */
export const BEHAVIOUR_LIST: ErrorBehavior[] = [
	{
		// ignore logout call
		behaviour: BEHAVIOUR.IGNORE,
		codes: [/.*/],
		methods: [/.*/],
		paths: [/dealership\/user\/logout/, /content\/contentItems\/.*\/update/],
	},
	{
		// ignore missing versions
		behaviour: BEHAVIOUR.IGNORE,
		codes: [/^404$/],
		methods: [/.*/],
		paths: [/ml-training\/models\/.*\/versions\/active/],
	},
	{
		// forbidden calls
		behaviour: BEHAVIOUR.FORBIDDEN,
		codes: [/^403$/],
		methods: [/.*/],
		paths: [/.*/],
	},
	{
		// Logout redirect to login page on unauthorised calls
		behaviour: BEHAVIOUR.LOGOUT,
		codes: [/^401$/],
		methods: [/.*/],
		paths: [/.*/],
	},
	{
		// redirect to the error page on user info call error
		behaviour: BEHAVIOUR.ERROR_PAGE,
		codes: [/.*/],
		methods: [/.*/],
		paths: [/dealership\/user\/info/],
	},
	{
		// redirect to the error page on campaigns call error
		behaviour: BEHAVIOUR.ERROR_PAGE,
		codes: [/.*/],
		methods: [/get|GET/],
		paths: [/crm\/campaigns/],
	},
	{
		// Show default error message on server/network errors
		behaviour: BEHAVIOUR.ERROR,
		codes: [/^5\d{2}$/, /^0$/],
		message: DEFAULT_ERROR_MESSAGE,
		methods: [/.*/],
		paths: [/.*/],
	},
	{
		// Show not found error message
		behaviour: BEHAVIOUR.ERROR,
		codes: [/^404$/],
		message: NOT_FOUND_ERROR_MESSAGE,
		methods: [/get|GET/],
		paths: [/.*/],
	},
	{
		// Display any other errors
		behaviour: BEHAVIOUR.ERROR,
		codes: [/.*/],
		methods: [/.*/],
		paths: [/.*/],
	},
];
