import type { StringifyOptions } from 'query-string';

export enum ROLES {
	ADMIN = 'Admin',
	ACCOUNT_MANAGER = 'Account Manager',
	SENIOR_ACCOUNT_MANAGER = 'Senior Account Manager',
	OPERATION = 'Operations',
	QA = 'QA',
	SALES_SPECIALIST = 'Sales Specialist',
	SUPPORT_SPECIALIST = 'Support Specialist',
	DEALER_SPECIALIST = 'Dealer Experience Specialist',
	DOCUMENT_REVIEWER = 'Document Reviewer',
	DEVELOPER = 'Developer',
	DEALER_SUPPORT = 'Dealer Support',
}

// user permissions
export enum PERMISSIONS {
	// CRM Permissions
	ACCESS_CRM_TRIGGER = 'access_crm_trigger',
	// Config Manager Permissions
	CONFIG_MANAGER_ACCESS = 'config_manager_access',
	CONFIG_MANAGER_CREATE = 'config_manager_create',
	CONFIG_MANAGER_READ = 'config_manager_read',
	CONFIG_MANAGER_WRITE = 'config_manager_write',
	CONFIG_MANAGER_DELETE = 'config_manager_delete',
	// Access MLOps Manager
	MLOPS_MANAGER_ACCESS = 'mlops_ui_access',
	// Other Engine Permissions
	AGENT_MANAGER_ACCESS = 'engine_access_agent_manager',
	SELLER_MANAGER_ACCESS = 'engine_access_seller_manager',
	SELLER_MANAGER_GDPR_ACCESS = 'engine_access_seller_gdpr',
	// User Permissions
	USERS_VIEW = 'users_view',
	USERS_MANAGEMENT = 'users_management',
	USERS_EDIT_PERMISSIONS = 'users_edit_permissions',
	USERS_EDIT_LEAD_ASSIGNMENT = 'users_edit_lead_assignment',
	USERS_EDIT_PROFILE_PICTURE = 'users_edit_profile_picture',
	// Dealer Permissions
	DEALERS_VIEW = 'dealers_view',
	DEALERS_ADD = 'dealers_add',
	DEALERS_MANAGEMENT = 'dealers_management',
	DEALERS_DELETE = 'dealers_delete',
	DEALERS_MODIFY_STATUS = 'dealers_modify_status',
	DEALERS_CONTACT_ADD = 'dealers_contact_add',
	DEALERS_KYC_MANAGEMENT = 'dealers_kyc_management',
	DEALER_EDIT_RETAIL_PRICE = 'edit_dealer_retail_price',
	DEALER_VIEW_EDIT_OTP_NUMBER = 'view_edit_dealer_otp_number',
	// Enquiry Permissions
	DEALERS_FEE_OVERRIDE = 'dealers_fee_override',
	VEHICLES_VIEW = 'vehicles_view',
	VEHICLE_UPDATE = 'vehicles_update',
	VEHICLE_LIST_STANDARD = 'vehicle_list_standard',
	VEHICLE_LIST_PRIORITISED = 'vehicle_list_prioritised',
	VEHICLE_LEAD_MANAGER_VIEW = 'senior_lead_manager_view',
	VEHICLE_SEE_BIDS_DURING_SALE = 'see_all_bids_during_sales',
	VEHICLE_REASSIGN_SOLD = 'vehicles_reassign_sold',
	VEHICLE_MODIFY_RESTRICTED_STATUS = 'modify_restricted_vehicle_status',
	VEHICLE_ASSIGN_SALE_SPECIALIST_RESTRICTED_STATE = 'assign_sales_specialist_in_restricted_state',
	VEHICLE_OVERRIDE_QA_VALIDATION_CHECK = 'vehicles_override_qa_validation_check',
	VEHICLE_UPDATE_SPECIFICATION_FIELDS = 'vehicles_update_specification_fields',
	VEHICLE_NOTES_DELETE = 'notes_delete',
	VEHICLE_DOCUMENTS_REVIEW = 'documents_review',
	VEHICLE_DOCUMENTS_ACCESS = 'documents_access',
	VEHICLE_DOCUMENTS_UPDATE_FIELDS = 'vehicles_update_documentation_fields',
	VEHICLE_GRADING_EDIT = 'grading_edit',
	VEHICLE_DELIVERY_EDIT = 'delivery_edit',
	VEHICLE_ANYTIME_REFRESH_PRICING = 'anytime_refresh_pricing',
	VEHICLE_EDIT_LOCKED_SALES_SPECIALIST = 'edit_locked_sales_specialist',
	// Payments
	PAYMENTS_VIEW = 'vehicle_payment_view',
	PAYMENTS_MARK_AS_MANUAL = 'mark_as_manual_payment',
	PAYMENTS_EDIT_PRICE_AMENDMENT = 'edit_price_amendment_for_payments',
	PAYMENTS_ACCESS_DEALER_CONTACT_PERMISSIONS = 'access_dealer_contact_permissions_for_payments',
	// Other
}

export const PERMISSION_GROUPS = [
	{
		label: 'Config Manager',
		data: [
			PERMISSIONS.CONFIG_MANAGER_ACCESS,
			PERMISSIONS.CONFIG_MANAGER_CREATE,
			PERMISSIONS.CONFIG_MANAGER_READ,
			PERMISSIONS.CONFIG_MANAGER_WRITE,
			PERMISSIONS.CONFIG_MANAGER_DELETE,
		],
	},
	{
		label: 'User',
		data: [
			PERMISSIONS.USERS_VIEW,
			PERMISSIONS.USERS_MANAGEMENT,
			PERMISSIONS.USERS_EDIT_PERMISSIONS,
			PERMISSIONS.USERS_EDIT_LEAD_ASSIGNMENT,
			PERMISSIONS.USERS_EDIT_PROFILE_PICTURE,
		],
	},
	{
		label: 'Dealer',
		data: [
			PERMISSIONS.DEALERS_VIEW,
			PERMISSIONS.DEALERS_ADD,
			PERMISSIONS.DEALERS_MANAGEMENT,
			PERMISSIONS.DEALERS_DELETE,
			PERMISSIONS.DEALERS_MODIFY_STATUS,
			PERMISSIONS.DEALERS_CONTACT_ADD,
			PERMISSIONS.DEALERS_FEE_OVERRIDE,
			PERMISSIONS.DEALER_EDIT_RETAIL_PRICE,
			PERMISSIONS.DEALER_VIEW_EDIT_OTP_NUMBER,
		],
	},
	{
		label: 'Enquiry',
		data: [
			PERMISSIONS.VEHICLES_VIEW,
			PERMISSIONS.VEHICLE_UPDATE,
			PERMISSIONS.VEHICLE_LIST_STANDARD,
			PERMISSIONS.VEHICLE_LIST_PRIORITISED,
			PERMISSIONS.VEHICLE_LEAD_MANAGER_VIEW,
			PERMISSIONS.VEHICLE_SEE_BIDS_DURING_SALE,
			PERMISSIONS.VEHICLE_REASSIGN_SOLD,
			PERMISSIONS.VEHICLE_MODIFY_RESTRICTED_STATUS,
			PERMISSIONS.VEHICLE_ASSIGN_SALE_SPECIALIST_RESTRICTED_STATE,
			PERMISSIONS.VEHICLE_OVERRIDE_QA_VALIDATION_CHECK,
			PERMISSIONS.VEHICLE_UPDATE_SPECIFICATION_FIELDS,
			PERMISSIONS.VEHICLE_NOTES_DELETE,
			PERMISSIONS.VEHICLE_GRADING_EDIT,
			PERMISSIONS.VEHICLE_DELIVERY_EDIT,
			PERMISSIONS.VEHICLE_ANYTIME_REFRESH_PRICING,
			PERMISSIONS.VEHICLE_EDIT_LOCKED_SALES_SPECIALIST,
		],
	},
	{
		label: 'Engine',
		data: [
			PERMISSIONS.ACCESS_CRM_TRIGGER,
			PERMISSIONS.MLOPS_MANAGER_ACCESS,
			PERMISSIONS.AGENT_MANAGER_ACCESS,
			PERMISSIONS.SELLER_MANAGER_ACCESS,
			PERMISSIONS.SELLER_MANAGER_GDPR_ACCESS,
		],
	},
	{
		label: 'Documents',
		data: [
			PERMISSIONS.VEHICLE_DOCUMENTS_REVIEW,
			PERMISSIONS.VEHICLE_DOCUMENTS_ACCESS,
			PERMISSIONS.VEHICLE_DOCUMENTS_UPDATE_FIELDS,
		],
	},
	{
		label: 'Payments',
		data: [
			PERMISSIONS.PAYMENTS_VIEW,
			PERMISSIONS.PAYMENTS_MARK_AS_MANUAL,
			PERMISSIONS.PAYMENTS_EDIT_PRICE_AMENDMENT,
			PERMISSIONS.PAYMENTS_ACCESS_DEALER_CONTACT_PERMISSIONS,
		],
	},
	{
		label: 'Other',
		data: [],
	},
] as const;

export const PERMISSIONS_LIST = Object.values(PERMISSIONS);

// loading
export const GLOBAL_LOADING = 'GLOBAL';

// timing
export const TIME_60_MINUTES = 60 * 60 * 1000;
export const TIME_24_HOURS = 60 * 60 * 1000 * 24;

// query-string
export const QUERY_STRING_CONFIG: StringifyOptions = {
	arrayFormat: 'none',
	skipEmptyString: true,
};

export enum PAGES {
	LOGIN = 'login',
	MODULES = 'modules',
	ERROR = 'error',
}

export enum VIEW_MODE {
	TABLE = 'table',
	CARDS = 'cards',
}

export const PUBLIC_PAGES = [PAGES.LOGIN, PAGES.ERROR];
