import { parseInt, reduce } from 'lodash-es';

import bps from '@/assets/styles/breakpoints.module.scss';

export interface Breakpoints {
	xs: number;
	sm: number;
	md: number;
	lg: number;
	xl: number;
	x2l: number;
	x3l: number;
}

export const breakpoints: Breakpoints = reduce(
	bps,
	(acc, val, key) => ({ ...acc, [key]: parseInt(val) }),
	{} as Breakpoints,
);
