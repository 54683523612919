import { shallowRef } from 'vue';
import type { RouteRecordRaw } from 'vue-router';
import ModuleIcon from '~icons/mdi/card-account-phone-outline';

import type { Module } from '@utils/model';

import { PAGES } from './constants';

const routes: RouteRecordRaw[] = [
	{
		path: '/seller-manager',
		name: PAGES.HOME,
		component: () => import(/* webpackChunkName: "slm" */ './pages/index.vue'),
		meta: {
			documentTitle: 'Seller Manager',
			title: 'Seller Manager',
			subtitle: 'Utils',
		},
		children: [
			{
				path: '',
				name: PAGES.UTILS,
				component: () => import(/* webpackChunkName: "slm" */ './pages/Utils/index.vue'),
				meta: {
					documentTitle: 'Seller Manager / Utils',
					title: 'Seller Manager',
					subtitle: 'Utils',
				},
			},
		],
	},
];

export const SELLER_MANAGER_MODULE: Module = {
	id: PAGES.HOME,
	link: { name: PAGES.UTILS },
	title: 'Seller Manager',
	icon: shallowRef(ModuleIcon),
	enabled: ({ canAccessSellerManager }) => canAccessSellerManager,
	routes,
};
